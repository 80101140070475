import "core-js/stable";
import "whatwg-fetch";

import("../app")
    .then((app) => {
        app.render();
    })
    .catch((e) => {
        console.error("import error:", e);
    });
